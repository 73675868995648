#body {
    overflow-x: hidden;
    margin: auto;
}

.hover-circle-affect:hover {
    border-style: solid;
    border-color: #3d52a0;
    border-width: 0.15rem;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.2), -2px -2px 5px rgba(255, 255, 255, 0.3);
}